import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service'

import { MenuService } from 'app/services/menu.service'

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit {

  url

  constructor(
    private http: HttpClient,
    private menuService: MenuService,
    private cookies: CookieService,
  ) { }

  ngOnInit() {
    this.url = this.menuService.getScreen('commissions').url+this.cookies.get('at') + '&mode='+(localStorage.getItem('theme') == 'grey' ? 'light' : 'dark')
  }

}
