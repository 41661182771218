// TODO: Change to auth module.
import { HttpBackend, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { environment } from '../../environments/environment'
import { catchError, throwError } from 'rxjs'
import { CookieService } from 'ngx-cookie-service'
import { Router } from '@angular/router'
import { Company } from './company.service'

interface Tokens {
  idToken: string
  accessToken: string
  expiresIn: number
  refreshToken: string
}

export interface LogInResponse {
  emailAddress: string
  userId: string
  tokens: Tokens
  userName: string
  isSuccess: boolean
  message: string | null
}

interface SignOutResponse {
  isSuccess: boolean
  message: string
}

interface ForgotAccountResponse {
  userId: string
  emailAddress: string
  isSuccess: boolean
  message: string
}

interface ForgotAccountCodeResponse {
  isSuccess: boolean
  message: string
}

interface ConfirmUserResponse {
  userId: string
  emailAddress: string
  isSuccess: boolean
  message: string
}

export interface UserProfileResponse {
  emailAddress: string
  emailVerified: string
  userId: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private cookies: CookieService,
    private router: Router,
    private handler: HttpBackend
  ) {
    this.httpNoAuth = new HttpClient(handler)
  }

  private httpNoAuth: HttpClient

  signin(email?: string | null, password?: string | null, mode?: string | null) {
    return this.http
      .post<LogInResponse>(`${environment.authApiUrl}/user/signin`, {
        email,
        password,
        mode
      })
      .pipe(
        catchError((e) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }
        })
      )
  }

  logout(token: string) {
    return this.http
      .post<SignOutResponse>(
        `${environment.authApiUrl}/user/logout/${token}`,
        null
      )
      .pipe(
        catchError((e) => {
          if (
            e.status === 500 &&
            e.error.Message !== 'Access Token has been revoked'
          ) {
            return throwError(() => new Error('Unexpected error!'))
          }
        })
      )
  }

  forgotAccount(email: string) {
    return this.http
      .post<ForgotAccountResponse>(
        `${environment.authApiUrl}/user/forgot/${email}`,
        null
      )
      .pipe(
        catchError((e) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }
        })
      )
  }

  forgotCode(email: string, newPassword: string, code: string) {
    return this.http.post<ForgotAccountCodeResponse>(
      `${environment.authApiUrl}/user/resetforgot`,
      {
        EmailAddress: email,
        NewPassword: newPassword,
        ConfirmationCode: code
      }
    )
  }

  refreshToken(token: string, email: string) {
    return this.http.post<LogInResponse>(
      `${environment.authApiUrl}/user/refreshtoken`,
      {
        EmailAddress: email,
        RefreshToken: token
      }
    )
  }

  confirmUser(userId: string, email: string, code: string, password: string) {
    return this.http.post<ConfirmUserResponse>(
      `${environment.authApiUrl}/user/confirm`,
      {
        UserId: userId,
        EmailAddress: email,
        ConfirmationCode: code,
        NewPassword : password
      }
    )
  }

  getUserInfo(email: string) {
    return this.http.get<UserProfileResponse>(
      `${environment.authApiUrl}/user/profile/${email}`
    )
  }

  checkUserDomain(email: string) {
    return this.httpNoAuth.get<Company>(
      `${environment.apiUrl}/user/company/${email}`
    )
  }

  clearSession() {
    this.cookies.delete('at')
    this.cookies.delete('rt')
    this.cookies.delete('it')
    const langs = localStorage.getItem('langs')
    localStorage.clear()
    localStorage.setItem('langs', langs)
    this.router.navigate(['login'])
  }
}
