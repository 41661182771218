import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'pluck',
  pure: false
})
export class PluckPipe implements PipeTransform {
  transform(value: any[], key: string): unknown {
    return value.map((item) => item[key]).join(', ')
  }
}
