<div class="{{(theme | async)}}">
  <div class="wrapper mat-app-background">
    <div class="sidebar" [attr.data-color]="dataColor" data-image="">
      <app-sidebar></app-sidebar>
      <div class="sidebar-background" [style.background-image]="sidebarBackgroundImage"></div>
    </div>
    <div class="main-panel">
      <navbar-cmp></navbar-cmp>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>