import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { AuthService } from '../../services/auth.service'
import { CookieService } from 'ngx-cookie-service'
import { NotificationService } from 'app/services/notification.service'
import { WINDOW } from 'app/app.module'
import { CompanyService } from 'app/services/company.service'
import { catchError, concatMap, EMPTY, iif, map } from 'rxjs'
import { UserService } from 'app/services/user.service'
import { ThemeService } from 'app/services/theme.service'
import { MenuService } from 'app/services/menu.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService,
    private userService: UserService,
    private themeService: ThemeService,
    private menuService: MenuService,
    private router: Router,
    private cookies: CookieService,
    private notification: NotificationService,
    @Inject(WINDOW) private window: Window
  ) {}

  domainImgLogo
  submitting = false

  loginForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required],
    mode: ['', Validators.required]
  })

  ngOnInit(): void {

    this.loginForm.controls["mode"].setValue('default');

    // Si se ejecuta en local siempre toma coperama por default
    var hostnamestring = this.window.location.hostname;
    if (this.window.location.hostname=="localhost")
    {
      var hostnamestring = "exclusivebenefits.wearevx.ai";
    }

    const hostname = hostnamestring; 
    const protocol = this.window.location.protocol

    this.companyService
      .getCompanies()
      .pipe(
        map((companies) =>
          companies.find(
            (company) => company.principal_url === `${protocol}//${hostname}`
          )
        )
      )
      .subscribe((company) => {
        if (company) {
          this.domainImgLogo = company.logo_url
          localStorage.setItem('domainImgLogo', this.domainImgLogo)
          localStorage.setItem('logoUrl', company.logo_url)
          localStorage.setItem('homeLogoUrl', company.home_logo_url)
          localStorage.setItem('companyId', company.id.toString())
          document.body.style.backgroundImage = `url(${company.background_login_url})`
          document.body.style.backgroundSize = "cover"
        }
      })
  }

  ngOnDestroy(): void {
    document.body.style.backgroundImage = null
  }

  onSubmit(): void {
    this.submitting = true
    this.authService
      .checkUserDomain(this.loginForm.value.email)
      .pipe(
        catchError((e) => {
          if (e.status === 401 || e.status === 400) {
            this.notification.error('Invalid credentials', 5000)
            this.loginForm.controls['password'].reset()
          }

          this.submitting = false

          return EMPTY
        }),
        concatMap(({ principal_url }) => {
          var originstring = window.location.origin;
          if (originstring.indexOf("localhost") != -1)
          {
            originstring="https://coperama.wearevx.ai";
          }
          else
          {
              if (principal_url !== originstring) return EMPTY
          }
          return this.authService.signin(
            this.loginForm.value.email,
            this.loginForm.value.password,
            this.loginForm.value.mode
          )
        }),
        concatMap(({ isSuccess, ...rest }) => {
          if (isSuccess) {
            const {
              tokens: { accessToken, refreshToken, idToken }
            } = rest

            this.cookies.set('at', accessToken, { secure: true, path: '/' })
            this.cookies.set('rt', refreshToken, { path: '/', secure: true })
            this.cookies.set('it', idToken, { secure: true, path: '/' })

            return this.userService.getUserConfig()
          } else {
            const { message } = rest

            if (message === 'INCORRECT_USERNAME_OR_PASSWORD') {
              this.loginForm.controls['password'].reset()
              this.submitting = false
              this.notification.error('Invalid credentials', 5000)
            }

            if (
              message === 'USER_NOT_CONFIRMED' ||
              message === 'EMAIL_ADDRESS_NOT_FOUND'
            ) {
              this.loginForm.controls['password'].reset()
              this.submitting = false
              this.notification.error('User not valid', 5000)
            }

            return EMPTY
          }
        })
      )
      .subscribe(
        ({
          theme: { name, sidebar_img, logo_url, language },
          menu: menus,
          submenus
        }) => {
          menus.map((menu) => {
            const menuSubmenus = submenus.filter(
              (submenu) => submenu.menu_id === menu.id && submenu.code != "NEGF"
            )

            menu.submenus = menuSubmenus
          })

          if(this.loginForm.value.mode == 'default'){
            this.themeService.setTheme(name)
          }else{
            this.themeService.setTheme(this.loginForm.value.mode)
          }
          
          this.themeService.setThemeImg(sidebar_img)

          localStorage.setItem('language', language)
          if (logo_url) localStorage.setItem('parentLogoUrl', logo_url)

          this.submitting = false
          this.menuService.addScreens(menus)

          if (!this.menuService.getScreen('DASHBOARDX')) { // dASHBOARD OBSOLETO
            /// TODO quitar este if y poner por base de datos el dashboadr inicial de cada compañia, hacerlo en la api de configuracion
            if (localStorage.getItem('companyId')=="19999"  || localStorage.getItem('companyId')=="4999" || localStorage.getItem('companyId')=="5999" || localStorage.getItem('companyId')=="8999")
            {
              this.router.navigate(['dashboard']);
            }
            else if (localStorage.getItem('companyId')=="3")
            {
              this.router.navigate(['collective']);
            }
/*            else if ()
            {
              this.router.navigate(['purchase']);
            }*/
            else if (localStorage.getItem('companyId')=="6" || localStorage.getItem('companyId')=="7" || localStorage.getItem('companyId')=="12" || localStorage.getItem('companyId')=="13" || localStorage.getItem('companyId')=="14")
            {
              this.router.navigate(['mk-middleware-Dashboard']);
            }
            else if (localStorage.getItem('companyId')=="11" || localStorage.getItem('companyId')=="18" )
            {
              this.router.navigate(['onboarding/ONBGSD']);
            }
            else if (localStorage.getItem('companyId')=="16")
            {
              this.router.navigate(['communications-dashboard']);
            }
            else
            {
              this.router.navigate(['dashboard']);  // Default purchase dashboard
            }



          } else {
            this.router.navigate(['dashboard'])
          }
        }
      )

      
  }
  
}
