import { Component, OnDestroy, OnInit } from '@angular/core'
import { Location } from '@angular/common'
import { EventsService } from './services/events.service'
import { Subscription } from 'rxjs'
import { Router } from '@angular/router'
import { AuthService } from './services/auth.service'
import { BreakpointObserver } from '@angular/cdk/layout'
import { MenuService } from 'app/services/menu.service'
import { LanguageService } from './services/language.service'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    public location: Location,
    private eventsService: EventsService,
    private languageService: LanguageService,
    private router: Router,
    private authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    private menuService: MenuService
  ) {}

  events: Subscription

  ngOnInit() {
    this.events = this.eventsService.on('logout', () => {
      this.logout()
    })

    const isSmallScreen =
      this.breakpointObserver.isMatched('(max-width: 991px)')

    if (isSmallScreen) {
      this.router.navigate(['smallscreen'])
    } else {
      const screen = this.menuService.getScreen(
        this.router.url.replace('/', '').toUpperCase()
      )

      if (
        screen === undefined &&
        !['/', '/login', '/forgot', '/privacy'].includes(this.router.url) &&
        this.router.url.split(',').length < 3
      ) {
        const nextScreen = this.menuService.getScreens()[0]
        this.router.navigateByUrl(nextScreen.code.toLowerCase())
      }
    }

    this.languageService.getLanguages().subscribe((languages) => {
      localStorage.setItem('langs', JSON.stringify(languages))
    })
  }

  logout() {
    this.authService.clearSession()
  }

  ngOnDestroy() {
    if (this.events) this.events.unsubscribe()
  }
}
