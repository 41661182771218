import { Injectable } from '@angular/core'
import { filter, map, Subject, Subscription } from 'rxjs'

export class EventData {
  name: string
  value: unknown

  constructor(name: string, value: unknown) {
    this.name = name
    this.value = value
  }
}

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private subject$ = new Subject<EventData>()

  emit(event: EventData) {
    this.subject$.next(event)
  }

  on(name: string, action: unknown): Subscription {
    return this.subject$
      .pipe(
        filter((e: EventData) => e.name === name),
        map((e: EventData) => e['value'])
      )
      .subscribe(action)
  }
}
