import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'

import { NormalLayoutRoutes } from './normal-layout.routing'

import { HomeComponent } from '../../views/home/home.component'
import { ProductsComponent } from '../../views/products/products.component'
import { ReportComponent } from 'app/components/report/report.component'

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { AlignmentComponent } from 'app/views/alignment/alignment.component'
import { MatchingToolComponent } from 'app/views/matching-tool/matching-tool.component'
import { UsersComponent } from 'app/views/users/users.component'
import { FrameComponent } from '../../components/frame/frame.component'
import { SubmenuTabsComponent } from '../../components/submenu-tabs/submenu-tabs.component'
import { UnderConstructionComponent } from 'app/components/under-construction/under-construction.component'
import { NegotiationComponent } from 'app/views/negotiation/negotiation.component'
import { NegotiationajilesComponent } from 'app/views/negotiationajiles/negotiationajiles.component'
import { OnboardingComponent } from 'app/views/onboarding/onboarding.component'
import { TranslationComponent } from 'app/views/translation/translation.component'
import { AddUserDialogComponent } from 'app/components/add-user-dialog/add-user-dialog.component'
import { NotFoundComponent } from 'app/components/not-found/not-found.component'
import { MatDialogModule } from '@angular/material/dialog'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core'
import { MatButtonModule } from '@angular/material/button'
import { MatSelectModule } from '@angular/material/select'
import { MatTabsModule } from '@angular/material/tabs'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { DatePipe } from '@angular/common'
import { ScrollingModule } from '@angular/cdk/scrolling'

import { SafePipe } from 'app/pipes/safe.pipe'
import { CpiComponent } from 'app/views/negotiation/cpi/cpi.component'
import { CpiajilesComponent } from 'app/views/negotiationajiles/cpiajiles/cpiajiles.component'
import { SubviewComponent } from 'app/components/subview/subview.component'
import { PriceEvolutionComponent } from 'app/views/negotiation/price-evolution/price-evolution.component'
import { NrSummaryComponent } from 'app/views/negotiation/nr-summary/nr-summary.component'
import { NegotiationsComponent } from 'app/views/negotiation/negotiations/negotiations.component'
import { NegotiationsajilesComponent } from 'app/views/negotiationajiles/negotiationsajiles/negotiationsajiles.component'
import { ListUsersFilterPipe } from 'app/pipes/list-users-filter.pipe'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { NgSelectModule } from '@ng-select/ng-select';
import { FilterPipe } from 'app/pipes/filter.pipe'
import { MultiselectDropdownComponent } from 'app/components/multiselect-dropdown/multiselect-dropdown.component'
import { PluckPipe } from 'app/pipes/pluck.pipe'
import { ModulePermissionsListComponent } from 'app/components/module-permissions-list/module-permissions-list.component'
import { CollectiveUserDashboardComponent } from 'app/views/collective-user-dashboard/collective-user-dashboard.component'
import { CollectiveProductDashboardComponent } from 'app/views/collective-product-dashboard/collective-product-dashboard.component'
import { PurchaseDashboardComponent } from 'app/views/purchase-dashboard/purchase-dashboard.component'
import { OrdersComponent } from 'app/views/orders/orders.component'
import { AlignmentsComponent } from 'app/views/alignment/alignments/alignments.component';
import { AlignmentsDashboardComponent } from 'app/views/alignment/alignments-dashboard/alignments-dashboard.component'
import { MkMiddlewareDashboardComponent } from 'app/views/mk-middleware-dashboard/mk-middleware-dashboard.component'
import { OnboardingsComponent } from 'app/views/onboarding/onboardings/onboardings.component';
import { OnboardingsDashboardComponent } from 'app/views/onboarding/onboardings-dashboard/onboardings-dashboard.component'
import { RetrainingsComponent } from 'app/views/onboarding/retrainings/retrainings.component';
import { RetrainingsDashboardComponent } from 'app/views/onboarding/retrainings-dashboard/retrainings-dashboard.component'
import { CustomerComponent } from 'app/views/customer/customer.component';
import { UsersCollectiveComponent } from 'app/views/users-collective/users-collective.component';
import { CommunicationsDashboardComponent } from 'app/views/communications-dashboard/communications-dashboard.component';
import { CommissionsComponent } from 'app/views/commissions/commissions.component'
import { InventoryvaluesComponent } from 'app/views/inventoryvalues/inventoryvalues.component'


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(NormalLayoutRoutes),
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    ScrollingModule,
    NgSelectModule
  ],
  declarations: [
    HomeComponent,
    ProductsComponent,
    AlignmentComponent,
    ReportComponent,
    MatchingToolComponent,
    UsersComponent,
    NegotiationComponent,
    NegotiationajilesComponent,
    OnboardingComponent,
    TranslationComponent,
    FrameComponent,
    SubmenuTabsComponent,
    UnderConstructionComponent,
    NotFoundComponent,
    AddUserDialogComponent,
    SafePipe,
    CpiComponent,
    CpiajilesComponent,
    NegotiationsComponent,
    NegotiationsajilesComponent,
    PriceEvolutionComponent,
    NrSummaryComponent,
    SubviewComponent,
    ListUsersFilterPipe,
    FilterPipe,
    PluckPipe,
    MultiselectDropdownComponent,
    ModulePermissionsListComponent,
    CollectiveUserDashboardComponent,
    CollectiveProductDashboardComponent,
    PurchaseDashboardComponent,
    OrdersComponent,
    AlignmentsComponent,
    AlignmentsDashboardComponent,
    MkMiddlewareDashboardComponent,
    OnboardingsComponent,
    OnboardingsDashboardComponent,
    RetrainingsComponent,
    RetrainingsDashboardComponent,
    CustomerComponent,
    UsersCollectiveComponent,
    CommunicationsDashboardComponent,
    CommissionsComponent,
    InventoryvaluesComponent,
  ],
  providers: [MatDatepickerModule, DatePipe]
})
export class NormalLayoutModule {}
