import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs'

export interface Menu {
  submenus?: Submenu[]
  id: number
  name: string
  code: string
  url: string
  icon: string
}

export interface MenuUserAdmin {
  id: number
  code: string
  name: string
  is_menu: boolean
  checked?: boolean
  depends_on?: number
  clients?: Client[] | number[]
  children?: MenuUserAdmin[]
  clients_allowed: boolean,
}

// TODO: Move to its own service, when its created.
export interface Client {
  id: number
  name: string
  company_id: number
  created_at?: Date
  updated_at?: Date
  deleted_at?: Date
}

export interface AdminMenu {
  menus: MenuUserAdmin[]
  clients: Client[]
}

export interface Submenu {
  id: number
  menu_id: number
  name: string
  url: string
  code: string
  icon: string
}

export type ClientListItem = {
  name: string
  value: number
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private _menu: BehaviorSubject<Menu[]> = new BehaviorSubject(
    JSON.parse(localStorage.getItem('menu'))
  )
  public readonly menu: Observable<Menu[]> = this._menu.asObservable()

  constructor(private http: HttpClient) {}

  addScreens(menu: Menu[]) {
    localStorage.setItem('menu', JSON.stringify(menu))
    this._menu.next(menu)
  }

  getScreen(name: string, isSubmenu = false) {
    const menu = this._menu
      .getValue()
      ?.find(
        (menu) =>
          menu.code === name.toUpperCase() ||
          menu.submenus?.find((submenu) => submenu.code === name.toUpperCase())
      )

    if (isSubmenu) {
      return menu?.submenus?.find(
        (submenu) => submenu.code === name.toUpperCase()
      )
    }

    return menu
  }

  getScreens() {
    return this._menu.getValue()
  }

  getUserScreens() {
    return this.http.get<Menu[]>(`${environment.apiUrl}/user/menu`).pipe(
      catchError((e) => {
        if (e.status === 500) {
          return throwError(() => new Error('Unexpected error!'))
        }
      })
    )
  }

  getSubmenus() {
    return this.http.get<Submenu[]>(`${environment.apiUrl}/menu/submenus`).pipe(
      catchError((e) => {
        if (e.status === 500)
          return throwError(() => new Error('Unexpected error!'))
      })
    )
  }

  getUsersAdministrationMenus() {
    return this.http.get<AdminMenu>(`${environment.apiUrl}/menu`).pipe(
      catchError((e) => {
        if (e.status === 500)
          return throwError(() => new Error('Unexpected error!'))
      })
    )
  }
}
