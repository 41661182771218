import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { NgOptimizedImage } from '@angular/common'
import { RouterModule } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'

import { AppRoutingModule } from './app.routing'
import { NavbarModule } from './components/navbar/navbar.module'
import { FooterModule } from './components/footer/footer.module'
import { SidebarModule } from './components/sidebar/sidebar.module'

import { AppComponent } from './app.component'

import { NormalLayoutComponent } from './layouts/normal-layout/normal-layout.component'
import { LoginModule } from './views/login/login.module'

import { InjectionToken, FactoryProvider } from '@angular/core'
import { AuthInterceptor } from 'app/interceptors/auth.interceptor'
import { ForgotComponent } from './views/forgot/forgot.component'
import { ConfirmComponent } from './views/confirm/confirm.component'
import { AuthService } from './services/auth.service'
import { EventsService } from './services/events.service'
import { NotificationService } from './services/notification.service'
import { SmallScreenComponent } from './views/small-screen/small-screen.component'
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig
} from 'ngx-cookieconsent'
import { PrivacyComponent } from './views/privacy/privacy.component'
import { ToastrModule } from 'ngx-toastr'
import { NgSelectModule } from '@ng-select/ng-select'
import { ForbidComponent } from './views/forbid/forbid.component';
import { CollectiveUserDashboardComponent } from './views/collective-user-dashboard/collective-user-dashboard.component';
import { CollectiveProductDashboardComponent } from './views/collective-product-dashboard/collective-product-dashboard.component';
import { PurchaseDashboardComponent } from './views/purchase-dashboard/purchase-dashboard.component';
import { MkMiddlewareDashboardComponent } from './views/mk-middleware-dashboard/mk-middleware-dashboard.component';
import { UsersCollectiveComponent } from './views/users-collective/users-collective.component'

export const WINDOW = new InjectionToken<Window>('window')

const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
}

export const WINDOW_PROVIDERS = [windowProvider]

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: window.location.hostname
  },
  showLink: true,
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: 'white',
      text: 'black'
    }
  },
  content: {
    message:
      'We use cookies to store and transfer information to our services.',
    href: `${window.location.protocol}//${window.location.host}/privacy`
  },
  theme: 'edgeless',
  type: 'info'
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    NavbarModule,
    FooterModule,
    SidebarModule,
    AppRoutingModule,
    LoginModule,
    NgOptimizedImage,
    SweetAlert2Module.forRoot({
      provideSwal: () => import('sweetalert2/dist/sweetalert2.js')
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    ToastrModule.forRoot()
  ],
  declarations: [
    AppComponent,
    NormalLayoutComponent,
    ForgotComponent,
    ConfirmComponent,
    SmallScreenComponent,
    PrivacyComponent,
    ForbidComponent,
    
  ],
  providers: [
    CookieService,
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      deps: [AuthService, EventsService, CookieService, NotificationService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
