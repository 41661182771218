import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { EventData, EventsService } from 'app/services/events.service'
import { NotificationService } from 'app/services/notification.service'
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenGuard implements CanActivate {
  constructor(
    private cookies: CookieService,
    private authService :AuthService,
    private eventsService: EventsService,
    private notifications: NotificationService

  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): 
    Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        const token = this.cookies.get('it');
    
        if (!token) {
          resolve(false); // No hay token, acceso denegado
        } else {
          const helper = new JwtHelperService();
          const claims = helper.decodeToken(token);
          const refreshToken = this.cookies.get('rt');
    
          if (helper.isTokenExpired(token)) {
            // Refrescar el token
            this.authService.refreshToken(refreshToken, claims.email)
              .subscribe((res) => {
                const {
                  tokens: { idToken, accessToken, refreshToken }
                } = res;
    
                this.cookies.delete('at');
                this.cookies.delete('it');
                this.cookies.delete('rt');
                this.cookies.set('at', accessToken, {
                  secure: true,
                  path: '/'
                });
                this.cookies.set('rt', refreshToken, {
                  path: '/',
                  secure: true
                });
                this.cookies.set('it', idToken, { secure: true, path: '/' });
                resolve(true); // Token refrescado, acceso permitido
              }, (error) => {
                console.error("Error refreshing  token");
                this.notifications.info('Session expired', 5000)

                this.eventsService.emit(new EventData('logout', null))
                
                resolve(false); // Error al refrescar el token, acceso denegado
              });
          } else {
            resolve(true); // Token válido, acceso permitido
          }
        }
      });
    }    
}
