<ng-template #template>
  <li routerLinkActive="active">
    <a [routerLink]="menuItem.path">
      <i class="{{menuItem.icon}}"></i>
      <p>{{menuItem.title}}</p>
    </a>
    <ul *ngIf="currentRoute === menuItem.path || onChildRoute" routerLinkActive="active">
      <li *ngFor="let submenu of menuItem.submenus" routerLinkActive="active">
        <a style="padding: 5px 15px;" [routerLink]="submenu.code">
          <i class="{{submenu.icon}}"></i>
          <p>{{submenu.name}}</p>
        </a>
      </li>
    </ul>
  </li>
</ng-template>