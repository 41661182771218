<div *ngIf="permissionList.length > 0" class="module-permissions-container">
  <div class="checkbox-item">
    <input type="checkbox" [(ngModel)]="selectedAll" (change)="toggleList()">
    <label>All</label>
  </div>
  <div class="module-permissions-list">
    <div *ngFor="let permission of permissionList; let i = index;" class="checkbox-item">
      <input type="checkbox" [(ngModel)]="permission.checked" (change)="isAllSelected()">
      <label>{{ permission.name ?? permission.code }}</label>
    </div>
  </div>
</div>