import { Component, OnInit } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { MenuService } from 'app/services/menu.service'
import { CookieService } from 'ngx-cookie-service'

declare const $: any
declare interface RouteInfo {
  path: string
  title: string
  class: string
  code?: string
}

// TODO: Maybe move to its own file.
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    code: 'DASHBOARD',
    class: ''
  },
  {
    path: '/commissions',
    title: 'Commissions',
    code: 'COMMISSIONS',
    class: ''
  },
  {
    path: '/customer',
    title: 'Customers',
    code: 'CUSTOMER',
    class: ''
  },
  {
    path: '/onboarding',
    title: 'Onboarding',
    code: 'ONBOARDINGSTATUS',
    class: ''
  },
  {
    path: '/ONBGS',
    title: 'Onboardings',
    code: 'ONBGS',
    class: ''
  },
  {
    path: '/ONBGSD',
    title: 'Onboardings dashboard',
    code: 'ONBGSD',
    class: ''
  },
  {
    path: '/RETGS',
    title: 'Retrainings',
    code: 'RETGS',
    class: ''
  },
  {
    path: '/RETGSD',
    title: 'Retrainings dashboard',
    code: 'RETGSD',
    class: ''
  },
  {
    path: '/products',
    title: 'Products detail',
    code: 'PRODUCTDETAIL',
    class: ''
  },
  {
    path: '/alignment',
    title: 'Alignment',
    code: 'ALIGNMENT',
    class: ''
  },
  {
    path: '/ALGS',
    title: 'Alignments',
    code: 'ALGS',
    class: ''
  },
  {
    path: '/ALGSD',
    title: 'Alignments dashboard',
    code: 'ALGSD',
    class: ''
  },
  {
    path: '/matchingTool',
    title: 'Matching tool',
    code: 'MATCHINGTOOL',
    class: ''
  },
  {
    path: '/collective',
    title: 'Collective user dashboard',
    code: 'EXBDASHUSERS',
    class: ''
  },
  {
    path: '/purchase',
    title: 'Purchase dashboard',
    code: 'DASHBOARDNEW',
    class: ''
  },
  {
    path: '/orders',
    title: 'Orders',
    code: 'DASHBOARDORDERS',
    class: ''
  },
  {
    path: '/collective-product',
    title: 'Collective prodduct dashboard',
    code: 'EXDASHPROD',
    class: ''
  },
  {
    path: '/mk-middleware-Dashboard',
    title: 'Mirakl middleware dashboard',
    code: 'MKMIDDLEWAREDASHBOADR',
    class: ''
  },
  {
    path: '/negotiation',
    title: 'Negotiation report',
    code: 'NEGOTIATION',
    class: ''
  },
  {
    path: '/negotiationajiles',
    title: 'Negotiation report',
    code: 'NEGOTIATIONAJILES',
    class: ''
  },
  {
    path: '/PREV',
    title: 'Price evolution',
    code: 'PREV',
    class: ''
  },
  {
    path: '/CPI',
    title: 'CPI',
    code: 'CPI',
    class: ''
  },
  {
    path: '/NEGV',
    title: 'Negotiations',
    code: 'NEGV',
    class: ''
  },
  {
    path: '/NRSUM',
    title: 'NR Summary',
    code: 'NRSUM',
    class: ''
  },
  
  {
    path: '/PREV',
    title: 'Price evolution',
    code: 'PREVAJILES',
    class: ''
  },
  {
    path: '/CPIAJILES',
    title: 'CPIAJILES',
    code: 'CPIAJILES',
    class: ''
  },
  {
    path: '/NEGVAJILES',
    title: 'Negotiations',
    code: 'NEGVAJILES',
    class: ''
  },
  {
    path: '/NRSUM',
    title: 'NR Summary',
    code: 'NRSUMAJILES',
    class: ''
  },
  {
    path: '/translationTool',
    title: 'Translation tool',
    code: 'TRANSLATIONTOOL',
    class: ''
  },
  {
    path: '/usersCollective',
    title: 'Users Collective',
    code: 'COLLECTIVEUSERS',
    class: ''
  },
  {
    path: '/communications-dashboard',
    title: 'Communications',
    code: 'DASHBOARDCOMMUNICATIONS',
    class: ''
  },

  {
    path: '/inventoryvalues',
    title: 'Inventory Values',
    code: 'INVENTORYVALUES',
    class: ''
  },

  {
    path: '/users',
    title: 'Users',
    code: 'USERS',
    class: ''
  },
]

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  username: string
  menuItems: any[]
  logoUrl: string

  constructor(
    private cookies: CookieService,
    private menuService: MenuService
  ) {
    this.logoUrl = localStorage.getItem('domainImgLogo')
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => {
      return this.menuService.getScreens().some((menu) => {
        return menu.code === menuItem.code
      })
    }).map(({ title, ...rest }) => {
      const itemToReplace = this.menuService
        .getScreens()
        .find((menu) => menu.code == rest.code)

      return {
        title: itemToReplace.name,
        icon: itemToReplace.icon,
        submenus: itemToReplace.submenus,
        ...rest
      }
    })

    const helper = new JwtHelperService()
    const claims = helper.decodeToken(this.cookies.get('it'))

    this.username = `${claims.name} ${claims.given_name} ${claims.middle_name}`
  }

  isMobileMenu() {
    if ($(window).width() > 991) {
      return false
    }

    return true
  }
}
