import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs'

export interface UserThemeResponse {
  id: string
  name: string
  hex: string
  language: string
  sidebar_img?: string
  logo_url?: string
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private _theme: BehaviorSubject<string> = new BehaviorSubject(
    `${localStorage.getItem('theme')}-theme`
  )
  private _themeImg: BehaviorSubject<string> = new BehaviorSubject(
    localStorage.getItem('themeImg')
  )
  public readonly theme: Observable<string> = this._theme.asObservable()
  public readonly themeImg: Observable<string> = this._themeImg.asObservable()

  constructor(private http: HttpClient) {}

  setTheme(theme: string) {
    this._theme.next(`${theme}-theme`)
    localStorage.setItem('theme', theme)
  }

  setThemeImg(img: string) {
    this._themeImg.next(img)
    localStorage.setItem('themeImg', img)
  }

  getTheme() {
    return this._theme.getValue()
  }

  getThemeImg() {
    return this._themeImg.getValue()
  }

  fetchUserTheme() {
    return this.http
      .get<UserThemeResponse>(`${environment.apiUrl}/user/theme`)
      .pipe(
        catchError((e) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }
        })
      )
  }
}
