<nav class="navbar navbar-default" [style.background-color]="navbarBackgroundColor">
  <div class="navbar-container container-fluid"> 
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" data-toggle="collapse" (click)="sidebarToggle()">
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      
      <div style="display: flex;" class="navbar-brand-container">

        <button type="button" class="navbar-toggle-size" (click)="sidebarCollapse()">
          <i class="pe-7s-angle-left"></i>
        </button>

        <div>
          <i class="{{getTitleIcon()}}"></i>
        </div>
        <a style="align-self: center;" class="navbar-brand">{{getTitle()}}</a>
        <div *ngIf = "getTitleDocumentation() != ''" class="">
            <a href="{{getTitleDocumentation()}}" target="_blank"><i class="pe-7s-help1 icon-documentation"></i></a>
        </div>
      </div>
    </div>
    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav navbar-left">
        <li>
        </li>
        <li>
          <a class="dropdown-toggle" data-toggle="dropdown">
            <p class="hidden-lg hidden-md">{{getTitle()}}</p>
          </a>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="navbar-logo-container">
          <img *ngIf="parentLogoUrl !== null" [src]="parentLogoUrl" class="navbar-parent-logo">
          <img [src]="logoUrl" class="navbar-logo" [class.navbar-parent-logo]="parentLogoUrl !== null">
        </li>
        <li>
          <a (click)="onLogOut()" class="navbar-logout">
            <i class="pe-7s-power navbar-logout-icon"></i> Logout
          </a>
        </li>
        <li class="separator hidden-lg hidden-md"></li>
      </ul>
    </div>
  </div>
</nav>