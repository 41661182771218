import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

import { LoginComponent } from './login.component'
import { RouterModule } from '@angular/router'

import { MatSelectModule } from '@angular/material/select'

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, RouterModule, MatSelectModule]
})
export class LoginModule {}
