<div class="forgot-page">
  <div class="forgot-container">
    <img src="assets/img/vx-management-logo.png" class="forgot-domain-logo">
    <div class="forgot-form-container">
      <h3 *ngIf="currentStep !== 3">Recover account</h3>
      <form [formGroup]="emailForm" (ngSubmit)="onEmailSubmit()" class="forgot-form" *ngIf="currentStep === 1">
        <label>Email</label>
        <input
          [class.forgot-input-invalid]="emailForm.controls['email'].invalid && (emailForm.controls['email'].dirty || emailForm.controls['email'].touched)"
          formControlName="email" class="forgot-form-input">
        <button [disabled]="emailForm.invalid || emailSubmitting"
          [class.forgot-form-submit-btn-invalid]="emailForm.invalid || emailSubmitting"
          class="button forgot-form-submit-btn" type="submit">
          {{!emailSubmitting ? 'Send' : '...'}}
        </button>
      </form>

      <form [formGroup]="codeForm" (ngSubmit)="onCodeSubmit()" class="forgot-form" *ngIf="currentStep === 2">
        <label>New password</label>
        <div class="login-form-password">
          <input #password
            [class.forgot-input-invalid]="codeForm.controls['newPassword'].invalid && (codeForm.controls['newPassword'].dirty || codeForm.controls['newPassword'].touched)"
            class="forgot-form-input" class="forgot-form-input" type="password" formControlName="newPassword">
          <button type="button" (click)="password.type = password.type === 'password' ? 'text' : 'password'">
            <i class="fa" [class.fa-eye]="password.type === 'password'"
              [class.fa-eye-slash]="password.type === 'text'"></i>
          </button>
        </div>
        <small id="passwordHelp" class="form-text text-muted">Password must have at least 8 characters, one uppercase,
          one lowercase, one special character and one number.</small>
        <label>Confirmation code</label>
        <input
          [class.forgot-input-invalid]="codeForm.controls['code'].invalid && (codeForm.controls['code'].dirty || codeForm.controls['code'].touched)"
          class="forgot-form-input" class="forgot-form-input" formControlName="code" type="text">
        <button class="button forgot-form-submit-btn" type="submit">{{!codeSubmitting ? 'Send' : '...'}}</button>
      </form>

      <div *ngIf="currentStep === 3" class="forgot-account-recovered">
        <h3 class="forgot-account-recovered-text">Account recovered!</h3>
        <div><a class="forgot-account-recovered-url" routerLink="/login">Go back to login</a></div>
      </div>

    </div>
  </div>
</div>