<div class="confirm-page">
  <div class="confirm-container">
    <img src="assets/img/vx-management-logo.png" class="confirm-domain-logo">
    <div class="confirm-form-container">
    <h3 *ngIf="!userConfirmed">Confirm user</h3>
    <form class="confirm-form" [formGroup]="confirmForm" *ngIf="!userConfirmed" (ngSubmit)="submitConfirm()">
      <label>Email</label>
      <input
        [class.vx-input-invalid]="confirmForm.controls['email'].invalid && (confirmForm.controls['email'].dirty || confirmForm.controls['email'].touched)"
        formControlName="email" class="vx-input">
      <label>Confirmation code</label>
      <input formControlName="code"
        [class.vx-input-invalid]="confirmForm.controls['code'].invalid && (confirmForm.controls['code'].dirty || confirmForm.controls['code'].touched)"
        class="button vx-input">
        <label>Password</label>
                 
          <input #password type="password" formControlName="password"  class="button vx-input" width="100%">
        <!--  <button type="button" (click)="password.type = password.type === 'password' ? 'text' : 'password'">
            <i class="fa" [class.fa-eye]="password.type === 'password'"
              [class.fa-eye-slash]="password.type === 'text'"></i>
          </button> -->
      
        <label>Confirm password</label>
        <input  type="password" formControlName="confirmpassword"  class="button vx-input">
        
        <div class="add-user-field-hints">
          <small>Password must have at least 8 characters, one uppercase, one lowercase, one special character and
            one number.</small>
        </div>
     
      <div>
        <button [class.vx-btn-invalid]="confirmForm.invalid || confirmationSubmitting" [disabled]="confirmForm.invalid || confirmationSubmitting" class="button vx-btn" type="submit">Confirm user</button>
      </div>
    </form>
    <div *ngIf="userConfirmed" class="confirm-account-confirmed">
      <h2>User confirmed!</h2>
      <a routerLink="/login">Go back to login</a>
    </div>
    <br/>
    </div>
  </div>
</div>