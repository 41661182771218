<div class="sidebar-wrapper">
  <div>
    <img src="assets/img/vx-management-logo.png" class="domain-logo-img">
  </div>
  <ul class="nav responsive-nav">
    <ng-container *ngFor="let menuItem of menuItems">
      <li routerLinkActive="active">
        <a [routerLink]="menuItem.path">
          <i class="{{menuItem.icon}}"></i>
          <p class="sidebar-menu-labels">{{menuItem.title}}</p>
        </a>
      </li>
    </ng-container>
  </ul>
</div>