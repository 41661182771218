import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mk-middleware-dashboard',
  templateUrl: './mk-middleware-dashboard.component.html',
  styleUrls: ['./mk-middleware-dashboard.component.scss']
})
export class MkMiddlewareDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
