import { HttpResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { AuthService } from 'app/services/auth.service'
import { NotificationService } from 'app/services/notification.service'
import { catchError, throwError } from 'rxjs'

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  currentStep = 1
  emailSubmitting = false
  codeSubmitting = false

  emailForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])]
  })

  codeForm = this.formBuilder.group({
    newPassword: ['', Validators.required],
    code: [
      '',
      Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])
    ]
  })

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private notificationService: NotificationService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const email = this.route.snapshot.queryParamMap.get('email')

    if (email !== null) {
      this.emailForm.value.email = email
      this.currentStep = 2
    }
  }

  onEmailSubmit() {
    this.emailSubmitting = true
    this.authService
      .forgotAccount(this.emailForm.value.email)
      .subscribe(({ isSuccess }) => {
        if (isSuccess) {
          this.currentStep = 2
          this.emailSubmitting = false
        } else {
          this.emailForm.reset()
          this.emailSubmitting = false
          this.notificationService.error('Invalid credentials', 5000)
        }
      })
  }

  onCodeSubmit() {
    this.codeSubmitting = true
    this.authService
      .forgotCode(
        this.emailForm.value.email,
        this.codeForm.value.newPassword,
        this.codeForm.value.code
      )
      .pipe(
        catchError((e: HttpResponse<any>) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }

          if (e.status === 400) {
            this.notificationService.error(
              'Password is not in the required format',
              5000
            )
          }

          this.codeSubmitting = false
        })
      )
      .subscribe(({ isSuccess, ...rest }) => {
        if (isSuccess) {
          this.currentStep = 3
          this.codeSubmitting = false
        } else {
          const { message } = rest

          if (
            message ===
              'Password does not conform to policy: Password not long enough' ||
            message ===
              'Password does not conform to policy: Password must have uppercase characters' ||
            message ===
              'Password does not conform to policy: Password must have numeric characters'
          ) {
            this.notificationService.error(message, 5000)
          } else {
            this.notificationService.error('Invalid credentials', 5000)
          }

          this.codeSubmitting = false
          this.codeForm.controls['code'].reset()
        }
      })
  }
}
