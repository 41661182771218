import { Component, OnInit } from '@angular/core'
import { Location, PopStateEvent } from '@angular/common'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import PerfectScrollbar from 'perfect-scrollbar'
import { filter, Observable, Subscription } from 'rxjs'
import { ThemeService } from 'app/services/theme.service'
import { MenuService } from 'app/services/menu.service'

@Component({
  selector: 'app-normal-layout',
  templateUrl: './normal-layout.component.html',
  styleUrls: ['./normal-layout.component.scss']
})
export class NormalLayoutComponent implements OnInit {
  private _router: Subscription
  private lastPoppedUrl: string
  private yScrollStack: number[] = []

  theme: Observable<string>
  dataColor: string
  sidebarBackgroundImage: string

  constructor(
    public location: Location,
    private router: Router,
    private themeService: ThemeService,
    private menuService: MenuService
  ) {}

  ngOnInit() {
    this.dataColor = localStorage.getItem('theme');

    const sidebarImagesGrey = ["grey/foto01_gris.png","grey/foto02_gris.png","grey/foto03_gris.png","grey/foto04_gris.png","grey/foto05_gris.png","grey/foto06_gris.png","grey/foto07_gris.png","grey/foto08_gris.png","grey/foto09_gris.png","grey/foto10_gris.png",
    "grey/foto11_gris.png","grey/foto12_gris.png","grey/foto13_gris.png","grey/foto14_gris.png","grey/foto15_gris.png","grey/foto16_gris.png","grey/foto17_gris.png","grey/foto18_gris.png","grey/foto19_gris.png","grey/foto20_gris.png"];

    const sidebarImagesBlack = ["black/foto01_negro.png","black/foto02_negro.png","black/foto03_negro.png","black/foto04_negro.png","black/foto05_negro.png","black/foto06_negro.png","black/foto07_negro.png","black/foto08_negro.png","black/foto09_negro.png","black/foto10_negro.png",
      "black/foto11_negro.png","black/foto12_negro.png","black/foto13_negro.png","black/foto14_negro.png","black/foto15_negro.png","black/foto16_negro.png","black/foto17_negro.png","black/foto18_negro.png","black/foto19_negro.png","black/foto20_negro.png"];
    
    const sidebarImageUrl = (this.dataColor == 'grey') ? sidebarImagesGrey[Math.floor(Math.random() * sidebarImagesGrey.length)] : sidebarImagesBlack[Math.floor(Math.random() * sidebarImagesBlack.length)];

    this.sidebarBackgroundImage = 'url("../../../assets/img/sidebar-images/'+sidebarImageUrl+'")';

    this.theme = this.themeService.theme
    
    const screens = this.menuService.getScreens()

    if (screens === null || screens.length === 0) {
      this.router.navigateByUrl('forbidden')
    }

    // document.getElementById(
    //   'sidebar-background'
    // ).style.backgroundImage = `url(${this.themeService.getThemeImg()})`

    const isWindows = navigator.platform.indexOf('Win') > -1 ? true : false

    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      document
        .getElementsByTagName('body')[0]
        .classList.add('perfect-scrollbar-on')
    } else {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('perfect-scrollbar-off')
    }
    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel')
    const elemSidebar = <HTMLElement>(
      document.querySelector('.sidebar .sidebar-wrapper')
    )

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url
    })

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY)
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined
          window.scrollTo(0, this.yScrollStack.pop())
        } else window.scrollTo(0, 0)
      }
    })

    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0
        elemSidebar.scrollTop = 0
      })
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel)
      ps = new PerfectScrollbar(elemSidebar)
    }
  }

  ngAfterViewInit() {
    this.runOnRouteChange()
  }

  isMap(path) {
    const titlee = this.location
      .prepareExternalUrl(this.location.path())
      .slice(1)

    if (path == titlee) {
      return false
    } else {
      return true
    }
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel')
      const ps = new PerfectScrollbar(elemMainPanel)
      ps.update()
    }
  }

  isMac(): boolean {
    if (
      navigator.platform.toUpperCase().indexOf('MAC') >= 0 ||
      navigator.platform.toUpperCase().indexOf('IPAD') >= 0
    ) {
      return true
    }
    return false
  }
}
