import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core'

interface ListItem {
  name: string
  value: unknown
  checked: boolean
}

@Component({
  selector: 'app-multiselect-dropdown[list][dropdownLabel]',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent implements OnInit {
  @Input() list: ListItem[]
  @Input() checkedItems = []
  @Input() dropdownLabel: string
  @Output() sharedCheckedList = new EventEmitter()
  @Output() sharedIndividualCheckedList = new EventEmitter()

  showDropdown = false
  cloneList: ListItem[]
  checkedList = []
  currentSelected = {}

  ngOnInit() {
    this.cloneList = this.list
      .map((item) => Object.assign({}, item))
      .map((item) => ({
        ...item,
        checked:
          this.checkedItems?.some((cItem) => cItem === item.value) ?? false
      }))
    this.checkedList = this.cloneList.filter((item) => item.checked)
  }

  getSelectedValue(status: boolean, value: unknown, name: string) {
    if (status) this.checkedList.push({ checked: status, value, name })
    else {
      const index = this.checkedList.findIndex((item) => item.value === value)
      this.checkedList.splice(index, 1)
    }

    this.currentSelected = { checked: status, value, name }
    this.shareCheckedList()
    this.shareIndividualCheckedItem()
  }

  shareCheckedList() {
    this.sharedCheckedList.emit(this.checkedList.map((item) => item.value))
  }

  shareIndividualCheckedItem() {
    this.sharedIndividualCheckedList.emit(this.currentSelected)
  }
}
