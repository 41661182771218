import { HttpResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { AuthService, UserProfileResponse } from 'app/services/auth.service'
import { NotificationService } from 'app/services/notification.service'
import { CreateUserResponseUnconfirmed } from 'app/services/user.service'
import { EMPTY, mergeMap, of, switchMap } from 'rxjs'

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private notification: NotificationService
  ) {}

  confirmationSubmitting = false
  userConfirmed = false

  confirmForm = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    code: [
      '',
      Validators.compose([Validators.required, Validators.pattern('^[0-9]*$')])
    ],
    password: [
      '',
      Validators.compose([
       Validators.required,
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*+-=])(?=.{8,})/
        ),
        Validators.minLength(8)
      ])
      
    ],
    confirmpassword: [
      '',
      Validators.compose([
       Validators.required,
        Validators.pattern(
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*+-=])(?=.{8,})/
        ),
        Validators.minLength(8)
      ])
    ] 
  })

  submitConfirm() {
    if (this.confirmForm.value.password === this.confirmForm.value.confirmpassword)
    {
        this.confirmationSubmitting = true
        this.authService
          .getUserInfo(this.confirmForm.value.email)
          .pipe(
            mergeMap((data: UserProfileResponse) => {
              if (data && data.emailVerified !== 'true' && data.userId)
                return this.authService.confirmUser(
                  data.userId,
                  this.confirmForm.value.email,
                  this.confirmForm.value.code,
                  this.confirmForm.value.password
                )
              else {
                this.confirmForm.reset()
                this.confirmationSubmitting = false
                this.notification.error('Invalid user/code', 5000)

                return EMPTY
              }
            })
          )
          .subscribe((data: CreateUserResponseUnconfirmed) => {
            if (data.isSuccess) {
              this.userConfirmed = true
            }

            this.confirmationSubmitting = false
          })
    } 
    else
    {
      this.confirmForm.reset()
      this.confirmationSubmitting = false
      this.notification.error('Invalid confirmation password', 5000)
    }
  }
}
