import { Component, OnInit } from '@angular/core';
import { MenuService } from 'app/services/menu.service'
import { CookieService } from 'ngx-cookie-service'
@Component({
  selector: 'app-alignments',
  templateUrl: './alignments.component.html',
  styleUrls: ['./alignments.component.scss']
})
export class AlignmentsComponent implements OnInit {

  url

  constructor(private menuService: MenuService,
    private cookies: CookieService) {}

  ngOnInit() {

    this.url = this.menuService.getScreen('ALGS', true).url + this.cookies.get('at') + '&mode='+(localStorage.getItem('theme') == 'grey' ? 'light' : 'dark')
  }

}
