import { Injectable } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http'
import { catchError, EMPTY, Observable, switchMap, throwError } from 'rxjs'
import { CookieService } from 'ngx-cookie-service'
import { AuthService, LogInResponse } from 'app/services/auth.service'
import { EventData, EventsService } from 'app/services/events.service'
import { JwtHelperService } from '@auth0/angular-jwt'
import { NotificationService } from 'app/services/notification.service'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false

  constructor(
    private authService: AuthService,
    private eventsService: EventsService,
    private cookies: CookieService,
    private notifications: NotificationService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const token = this.cookies.get('it')

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }

    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401 && !this.isRefreshing) {
          this.isRefreshing = true

          const helper = new JwtHelperService()
          const refreshToken = this.cookies.get('rt')

          const claims = helper.decodeToken(token)

          return this.authService.refreshToken(refreshToken, claims.email).pipe(
            catchError((e) => {
              if (e.status === 401) {
                this.isRefreshing = false
                this.notifications.info('Session expired', 5000)

                this.eventsService.emit(new EventData('logout', null))

                return EMPTY
              }
            }),
            switchMap((res: LogInResponse) => {
              const {
                tokens: { idToken, accessToken, refreshToken }
              } = res

              this.cookies.delete('at')
              this.cookies.delete('it')
              this.cookies.delete('rt')
              this.cookies.set('at', accessToken, {
                secure: true,
                path: '/'
              })
              this.cookies.set('rt', refreshToken, {
                path: '/',
                secure: true
              })
              this.cookies.set('it', idToken, { secure: true, path: '/' })

              this.isRefreshing = false

              return next.handle(
                req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${idToken}`
                  }
                })
              )
            })
          ) as Observable<HttpEvent<LogInResponse>>
        }

        this.isRefreshing = false

        return throwError(() => e)
      })
    )
  }
}
