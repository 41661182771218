<div class="login-page">
  <content class="login-container">
    <div class="login-header">
      <div class="login-logo">
        <img src="assets/img/vx-management-logo.png">
        <div class="login-logo-panel">
          <h4>Who is Smart Management?</h4>
          <span class="login-logo-panel-content">
            <p>
              Quality analytical information. KPIs in real time for continuous evaluation and decision-making based on
              the variables that influence the profitability of the business until reaching predictive analytics
            </p>
          </span>
        </div>
      </div>
      <div class="login-panel-container">
        <img [src]="domainImgLogo" class="login-domain-logo">
        <div class="login-form-container">
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
            <label>Log in here:</label>
            <input
              [class.login-input-invalid]="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)"
              id="email" class="login-form-input" placeholder="E-mail" type="email" formControlName="email">
            <div class="login-form-password">
              <input #password
                [class.login-input-invalid]="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)"
                id="password" class="login-form-input" placeholder="Password" type="password"
                formControlName="password">
              <button type="button" (click)="password.type = password.type === 'password' ? 'text' : 'password'">
                <i class="fa" [class.fa-eye]="password.type === 'password'"
                  [class.fa-eye-slash]="password.type === 'text'"></i>
              </button>
            </div>
            <div class="inline-form-fields">
              <div class="login-form-mode">
                <mat-form-field>
                  <mat-select 
                    [class.login-input-invalid]="loginForm.controls['mode'].invalid && (loginForm.controls['mode'].dirty || loginForm.controls['mode'].touched)"
                    id="mode" formControlName="mode">
                    <mat-select-trigger>
                      <span *ngIf="loginForm.controls['mode'].value === 'default'">
                        <i class="fa fa-adjust" aria-hidden="true"></i> Default mode                      
                      </span>
                      <span *ngIf="loginForm.controls['mode'].value === 'black'">
                        <i class="fa fa-moon-o" aria-hidden="true"></i> Dark                      
                      </span>
                      <span *ngIf="loginForm.controls['mode'].value === 'grey'">
                        <i class="fa fa-sun-o" aria-hidden="true"></i> Light                      
                      </span>
                    </mat-select-trigger>
                    <mat-option value="default"><span><i class="fa fa-adjust" aria-hidden="true"></i> Default mode</span></mat-option>
                    <mat-option value="black"><span><i class="fa fa-moon-o" aria-hidden="true"></i> Dark</span></mat-option>
                    <mat-option value="grey"><span><i class="fa fa-sun-o" aria-hidden="true"></i> Light</span></mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="login-form-actions">
                <div>
                  <button [disabled]="loginForm.invalid || submitting"
                    [class.login-form-submit-btn-invalid]="loginForm.invalid || submitting"
                    class="button login-form-submit-btn" type="submit">
                    {{submitting ? '...' : 'Log in'}}
                  </button>
                </div>
                <div>
                  <a routerLink="/forgot">Forgot your password?</a>
                </div>
              </div>
            </div>         

          </form>
        </div>
      </div>
    </div>
  </content>
  <footer class="login-footer">
    <div class="login-footer-content">
      <span>powered by</span>
      <div>
        <span>
          2022 Viavox. All Rights Reserved -
          <a>Privacy policy</a> -
          <a>Contact</a>
        </span>
      </div>
    </div>
  </footer>
</div>