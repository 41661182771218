import { Component, OnInit } from '@angular/core';

import { HttpClient } from '@angular/common/http'
import { MenuService } from 'app/services/menu.service'
import { CookieService } from 'ngx-cookie-service'

@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.scss']
})
export class TranslationComponent implements OnInit {
  url 

  constructor(
    private http: HttpClient,
    private menuService: MenuService,
    private cookies: CookieService
  ) { }

  ngOnInit(): void {
    this.url = this.menuService.getScreen('TRANSLATIONTOOL').url + this.cookies.get('at') + '&mode='+(localStorage.getItem('theme') == 'grey' ? 'light' : 'dark')
  }

}
