import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { ComponentStore } from '@ngrx/component-store'
import { EventData, EventsService } from 'app/services/events.service'
import { BehaviorSubject } from 'rxjs'

@Component({
  selector: 'app-navbutton',
  templateUrl: './navbutton.component.html',
  styleUrls: ['./navbutton.component.scss'],
  providers: [ComponentStore]
})
export class NavbuttonComponent implements OnInit {
  @ViewChild('template', { static: true }) template

  constructor(public router: Router, private view: ViewContainerRef) {
    router.events.subscribe((e: NavigationEnd) => {
      this.currentRoute = e.url

      if (this.menuItem.submenus.some((x) => `/${x.code}` === e.url))
        this.onChildRoute = true
      else this.onChildRoute = false
    })
  }

  @Input() menuItem: any
  public currentRoute: string
  public onChildRoute: boolean

  ngOnInit(): void {
    if (this.menuItem.submenus.some((x) => `/${x.code}` === this.router.url))
      this.onChildRoute = true
    else this.onChildRoute = false

    this.view.createEmbeddedView(this.template)
    this.currentRoute = this.router.url
  }
}
