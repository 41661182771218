import { Component, OnInit } from '@angular/core'
import { MenuService } from 'app/services/menu.service'
import { CookieService } from 'ngx-cookie-service'

@Component({
  selector: 'app-cpi',
  templateUrl: './cpi.component.html',
  styleUrls: ['./cpi.component.scss']
})
export class CpiComponent implements OnInit {
  url

  constructor(
    private menuService: MenuService,
    private cookies: CookieService
  ) {}

  ngOnInit(): void {
    this.url =
      this.menuService.getScreen('CPI', true).url + this.cookies.get('at') + '&mode='+(localStorage.getItem('theme') == 'grey' ? 'light' : 'dark')
  }
}
