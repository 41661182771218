import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(
    items: unknown[],
    callback: (item: unknown, ...args: unknown[]) => boolean,
    ...args: unknown[]
  ): any {
    if (!items || !callback) {
      return items
    }

    return items.filter((item) => callback(item, ...args))
  }
}
