import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { MenuService } from 'app/services/menu.service'
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk'

// import { embedDashboard } from 'amazon-quicksight-embedding-sdk'

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private menuService: MenuService,
    private sanitizer: DomSanitizer
  ) {}

  params = {
    idCompany: 10
  }

  async ngOnInit() {
    const screenUrl = this.menuService.getScreen('Productdetail')?.url
    const embeddingContext = await createEmbeddingContext();

    const {
      embedDashboard
    } = embeddingContext;

    if (screenUrl) {
      this.http.get<string>(screenUrl).subscribe(async (url) => {

        const options = {
          url,
          container: document.getElementById('dashboardContainer'),
          undoRedoDisabled: true,
          resetDisabled: true
        }
        var contentOptions = {  
        }
        
        var vxtheme = localStorage.getItem('theme') =='grey'? 'light' : 'dark';
        
        // CLASSIC -> theme light
        if(vxtheme == 'light'){
          contentOptions = {  
            themeOptions: {
              themeArn: "arn:aws:quicksight::aws:theme/CLASSIC"
            },
            parameters: [
              {
                  Name: "themename",
                  Values: [vxtheme]
              }
            ]
          }
        }
    
        var embebeddashboard = await embedDashboard(options, contentOptions);
    
        // embedDashboard(options)
      })
    }
  }
}
