import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core'
import { MenuUserAdmin } from 'app/services/menu.service'

@Component({
  selector: 'app-module-permissions-list[permissions][moduleId]',
  templateUrl: './module-permissions-list.component.html',
  styleUrls: ['./module-permissions-list.component.scss']
})
export class ModulePermissionsListComponent implements OnInit {
  @Input() permissions: MenuUserAdmin[]
  @Input() moduleId: number
  @Output() sharedCheckedList = new EventEmitter()
  permissionList: MenuUserAdmin[]
  selectedAll = false
  checkedList: MenuUserAdmin[]

  ngOnInit(): void {
    this.selectedAll = false
    this.permissionList = this.permissions
      .map((item) => Object.assign({}, item))
      .filter((permission) => permission.depends_on === this.moduleId)
    this.checkedList = this.permissionList.filter(
      (item) => item.checked === true
    )

    this.computedCheckedList(true)
  }

  toggleList() {
    for (let i = 0; i < this.permissionList.length; i += 1) {
      this.permissionList[i].checked = this.selectedAll
    }

    this.computedCheckedList()
  }

  isAllSelected() {
    this.selectedAll = this.permissionList.every(
      (permission) => permission.checked
    )

    this.computedCheckedList()
  }

  computedCheckedList(start = false) {
    this.checkedList = []
    for (let i = 0; i < this.permissionList.length; i += 1) {
      if (this.permissionList[i].checked)
        this.checkedList.push(this.permissionList[i])
    }
    this.checkedList = this.checkedList.map((item) => Object.assign({}, item))
    if (!start)
      this.sharedCheckedList.emit(this.checkedList.map((item) => item.id))
  }
}
