import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { SidebarComponent } from './sidebar.component'
import { NavbuttonComponent } from '../navbutton/navbutton.component'

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [SidebarComponent, NavbuttonComponent],
  exports: [SidebarComponent]
})
export class SidebarModule {}
