<div class="multiselect-container">
  <div (mouseleave)="showDropdown = false" class="multiselect">
    <button (click)="showDropdown = !showDropdown" class="dropdown-btn">
      <div>
        <span *ngIf="checkedList.length <= 0">{{ dropdownLabel }}</span>
        <span *ngIf="checkedList.length > 0">{{ checkedList | pluck:'name' }}</span>
      </div>
      <i class="fa" [class.fa-angle-down]="!showDropdown" [class.fa-angle-up]="showDropdown"></i>
    </button>
    <cdk-virtual-scroll-viewport [hidden]="!showDropdown" itemSize="10" class="dropdown-list">
      <label *cdkVirtualFor="let item of cloneList">
        <input type="checkbox" [(ngModel)]="item.checked"
          (change)="getSelectedValue(item.checked, item.value, item.name)">
        <span>{{ item.name }}</span>
      </label>
    </cdk-virtual-scroll-viewport>
  </div>
</div>