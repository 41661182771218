import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { Routes, RouterModule } from '@angular/router'

import { NormalLayoutComponent } from './layouts/normal-layout/normal-layout.component'
import { LoginComponent } from './views/login/login.component'
import { ForgotComponent } from './views/forgot/forgot.component'
import { ConfirmComponent } from './views/confirm/confirm.component'
import { SmallScreenComponent } from './views/small-screen/small-screen.component'
import { PrivacyComponent } from './views/privacy/privacy.component'

const routes: Routes = [
  {
    path: 'smallscreen',
    component: SmallScreenComponent,
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full'
  },
  {
    path: 'forgot',
    component: ForgotComponent,
    pathMatch: 'full'
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: NormalLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./layouts/normal-layout/normal-layout.module').then(
            (x) => x.NormalLayoutModule
          )
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
]

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: false
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
