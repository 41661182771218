import { Component } from '@angular/core'

@Component({
  selector: 'app-negotiation',
  templateUrl: './negotiationajiles.component.html',
  styleUrls: ['./negotiationajiles.component.scss']
})
export class NegotiationajilesComponent {
  
}
