import { Injectable } from '@angular/core'
import { ToastrService } from 'ngx-toastr'

type From = 'bottom' | 'top'
type To = 'left' | 'right' | 'center'

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  error(msg: string, time: number) {
    this.toastr.error(msg, 'Error', {
      positionClass: 'toast-bottom-right',
      timeOut: time
    })
  }

  warning(msg: string, time: number) {
    this.toastr.warning(msg, 'Warning', {
      positionClass: 'toast-bottom-right',
      timeOut: time
    })
  }

  info(msg: string, time: number) {
    this.toastr.info(msg, 'Information', {
      positionClass: 'toast-bottom-right',
      timeOut: time
    })
  }

  success(msg: string, time: number) {
    this.toastr.info(msg, 'Success', {
      positionClass: 'toast-bottom-right',
      timeOut: time
    })
  }
}
