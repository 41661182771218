import { Component, OnInit, Input } from '@angular/core';
import { MenuService } from 'app/services/menu.service'
import { ROUTES } from '../sidebar/sidebar.component'

@Component({
  selector: 'app-submenu-tabs',
  templateUrl: './submenu-tabs.component.html',
  styleUrls: ['./submenu-tabs.component.scss']
})
export class SubmenuTabsComponent implements OnInit {

  @Input() section!: string;
  menuItems: any[]
  dataColor: string

  constructor(
    private menuService: MenuService
  ) { }

  ngOnInit() {

    this.menuItems = ROUTES.filter((menuItem) => {
      return this.menuService.getScreens().some((menu) => {
        return (menu.code === menuItem.code &&  menu.code === this.section)
      })
    }).map(({ title, ...rest }) => {
      const itemToReplace = this.menuService
        .getScreens()
        .find((menu) => menu.code == rest.code)

      return {
        title: itemToReplace.name,
        icon: itemToReplace.icon,
        submenus: itemToReplace.submenus,
        ...rest
      }
    })

    this.dataColor = localStorage.getItem('theme')

  }

}
