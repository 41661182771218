import { Routes } from '@angular/router'

import { HomeComponent } from '../../views/home/home.component'
import { ProductsComponent } from '../../views/products/products.component'
import { AuthGuard } from 'app/guards/auth.guard'
import { AlignmentComponent } from 'app/views/alignment/alignment.component'
import { MatchingToolComponent } from 'app/views/matching-tool/matching-tool.component'
import { UsersComponent } from 'app/views/users/users.component'
import { OrdersComponent } from 'app/views/orders/orders.component'
import { NegotiationComponent } from 'app/views/negotiation/negotiation.component'
import { NegotiationajilesComponent } from 'app/views/negotiationajiles/negotiationajiles.component'
import { OnboardingComponent } from 'app/views/onboarding/onboarding.component'
import { TranslationComponent } from 'app/views/translation/translation.component'
import { CpiComponent } from 'app/views/negotiation/cpi/cpi.component'
import { CpiajilesComponent } from 'app/views/negotiationajiles/cpiajiles/cpiajiles.component'
import { NegotiationsComponent } from 'app/views/negotiation/negotiations/negotiations.component'
import { NegotiationsajilesComponent } from 'app/views/negotiationajiles/negotiationsajiles/negotiationsajiles.component'
import { PriceEvolutionComponent } from 'app/views/negotiation/price-evolution/price-evolution.component'
import { NrSummaryComponent } from 'app/views/negotiation/nr-summary/nr-summary.component'
import { ForbidComponent } from 'app/views/forbid/forbid.component'
import { AuthPermissionsGuard } from 'app/guards/auth-permissions.guard'
import { RefreshTokenGuard } from 'app/guards/refresh-token.guard'
import { CommunicationsDashboardComponent } from 'app/views/communications-dashboard/communications-dashboard.component'
import { CollectiveUserDashboardComponent } from 'app/views/collective-user-dashboard/collective-user-dashboard.component'
import { CollectiveProductDashboardComponent } from 'app/views/collective-product-dashboard/collective-product-dashboard.component'
import { PurchaseDashboardComponent } from 'app/views/purchase-dashboard/purchase-dashboard.component'
import { AlignmentsComponent } from 'app/views/alignment/alignments/alignments.component';
import { AlignmentsDashboardComponent } from 'app/views/alignment/alignments-dashboard/alignments-dashboard.component'
import { MkMiddlewareDashboardComponent } from 'app/views/mk-middleware-dashboard/mk-middleware-dashboard.component'
import { OnboardingsComponent } from 'app/views/onboarding/onboardings/onboardings.component';
import { OnboardingsDashboardComponent } from 'app/views/onboarding/onboardings-dashboard/onboardings-dashboard.component'
import { RetrainingsComponent } from 'app/views/onboarding/retrainings/retrainings.component';
import { RetrainingsDashboardComponent } from 'app/views/onboarding/retrainings-dashboard/retrainings-dashboard.component'
import { CustomerComponent } from 'app/views/customer/customer.component';
import { UsersCollectiveComponent } from 'app/views/users-collective/users-collective.component';
import { CommissionsComponent } from 'app/views/commissions/commissions.component'
import { InventoryvaluesComponent } from 'app/views/inventoryvalues/inventoryvalues.component'

export const NormalLayoutRoutes: Routes = [
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },

  {
    path: 'commissions',
    component: CommissionsComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard, RefreshTokenGuard]
  },

  {
    path: 'inventoryvalues',
    component: InventoryvaluesComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard, RefreshTokenGuard]
  },

  {
    path: 'alignment',
    component: AlignmentComponent,
    canActivateChild: [AuthPermissionsGuard],
    children: [
      { 
        path: 'ALGS', 
        component: AlignmentsComponent,
        canActivate: [RefreshTokenGuard]
      },
      { 
        path: 'ALGSD', 
        component: AlignmentsDashboardComponent,
        canActivate: [RefreshTokenGuard]
      },
      { path: '', redirectTo: 'ALGSD', pathMatch: 'full' }
    ]
  },

  {
    path: 'matchingTool',
    component: MatchingToolComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard, RefreshTokenGuard]
  },
  {
    path: 'collective',
    component: CollectiveUserDashboardComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard]
  },
  {
    path: 'mk-middleware-Dashboard',
    component: MkMiddlewareDashboardComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard]
  },
  {
    path: 'collective-product',
    component: CollectiveProductDashboardComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard]
  },
  {
    path: 'purchase',
    component: PurchaseDashboardComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard, RefreshTokenGuard]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard, RefreshTokenGuard]
  },
  {
    path: 'negotiation',
    component: NegotiationComponent,
    canActivateChild: [AuthPermissionsGuard],
    children: [
      { 
        canActivate: [RefreshTokenGuard],
        path: 'NEGV', 
        component: NegotiationsComponent,    
      },
      { 
        path: 'CPI', 
        component: CpiComponent,
        canActivate: [RefreshTokenGuard],
      },
      { 
        path: 'PREV', 
        component: PriceEvolutionComponent,
        canActivate: [RefreshTokenGuard]      
      },
      { 
        path: 'NRSUM', 
        component: NrSummaryComponent,
        canActivate: [RefreshTokenGuard]     
      },
      { path: '', redirectTo: 'NRSUM', pathMatch: 'full' }
    ]
  },
  {
    path: 'negotiationajiles',
    component: NegotiationajilesComponent,
    canActivateChild: [AuthPermissionsGuard],
    children: [
      { 
        canActivate: [RefreshTokenGuard],
        path: 'NEGVAJILES', 
        component: NegotiationsajilesComponent,    
      },
      { 
        path: 'CPIAJILES', 
        component: CpiajilesComponent,
        canActivate: [RefreshTokenGuard],
      },
      { path: '', redirectTo: 'NEGVAJILES', pathMatch: 'full' }
    ]
  },
  {
    path: 'onboarding',
    component: OnboardingComponent,
    canActivateChild: [AuthPermissionsGuard],
    children: [
      { 
        path: 'ONBGS', 
        component: OnboardingsComponent,
        canActivate: [RefreshTokenGuard]
      },
      { 
        path: 'ONBGSD', 
        component: OnboardingsDashboardComponent,
        canActivate: [RefreshTokenGuard]
      },
      { 
        path: 'RETGS', 
        component: RetrainingsComponent,
        canActivate: [RefreshTokenGuard]
      },
      { 
        path: 'RETGSD', 
        component: RetrainingsDashboardComponent,
        canActivate: [RefreshTokenGuard]
      },
      { path: '', redirectTo: 'ONBGSD', pathMatch: 'full' }
    ]    
  },
  { 
    path: 'communications-dashboard', 
    component: CommunicationsDashboardComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'customer',
    component: CustomerComponent,
    data: { topLevelRoute: true },
    canActivate: [AuthGuard, RefreshTokenGuard]
  },
  {
    path: 'translationTool',
    component: TranslationComponent,
    canActivate: [AuthGuard, RefreshTokenGuard]
  },
  {
    path: 'usersCollective',
    component: UsersCollectiveComponent,
    canActivate: [AuthGuard, RefreshTokenGuard]
  },
  {
    path: 'forbidden',
    component: ForbidComponent
  }
]
