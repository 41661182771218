import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { MenuService } from 'app/services/menu.service'
import { CookieService } from 'ngx-cookie-service'
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class AuthPermissionsGuard implements CanActivateChild {
  constructor(
    private router: Router,
    private cookies: CookieService,
    private menuService: MenuService,
    private route: ActivatedRoute
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.cookies.get('at')

    if (!token) return this.router.parseUrl('/login')

    const path = state.url.split('/')
    const parent = path[1]
    const [child] = path.slice(-1)

    if (
      !this.menuService.getScreen(child.toUpperCase(), true) &&
      !childRoute.parent.data.childDontNeedPermissions
    ) {
      const parentMenu = this.menuService.getScreen(parent.toUpperCase())

      if ('submenus' in parentMenu && parentMenu.submenus.length > 0) {
        console.log('here')
        return this.router.parseUrl(
          `/${parent}/${parentMenu.submenus[0].code.toUpperCase()}`
        )
      }

      return this.router.parseUrl('/forbidden')
    }

    return true
  }
}
