import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { BaseResponse } from 'interfaces/base-response'
import { catchError, EMPTY, throwError } from 'rxjs'
import { Menu, Submenu } from './menu.service'
import { UserThemeResponse } from './theme.service'

export interface User {
  id: number
  email: string
  role: string
  role_id?: number
  created_at: Date
  updated_at: Date
  last_access: Date
}

export interface UserProfile {
  language: number
  name: string
  last_name: string
  email: string
  modules: Module[]
}

export interface UserCognito {
  name: string
  lastName: string
  email: string
}

export interface CreateUserData {
  name: string
  lastName: string
  language: number
  email: string
  password: string
  modules: EditUserRequestModules[]
}

export interface CreateUserResponseUnconfirmed {
  isSuccess: boolean
  message: string
}

interface EditUserRequestModules {
  module_id: number
  clients?: number[]
}

export interface EditUserRequest {
  name: string
  last_name: string
  email: string
  language: number
  password?: string
  modules: EditUserRequestModules[]
}

export interface Module {
  id: number
  clients?: number[]
  clients_allowed: boolean
}

export interface UserConfig {
  theme: UserThemeResponse
  menu: Menu[]
  submenus: Submenu[]
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUser(user: string) {
    return this.http.get<User>(`${environment.apiUrl}/user/${user}`).pipe(
      catchError((e) => {
        if (e.status === 500) {
          return throwError(() => new Error('Unexpected error!'))
        }

        return EMPTY
      })
    )
  }

  getUserConfig() {
    return this.http.get<UserConfig>(`${environment.apiUrl}/user/config`).pipe(
      catchError((e) => {
        if (e.status === 500) {
          return throwError(() => new Error('Unexpected error!'))
        }

        return EMPTY
      })
    )
  }

  getUserProfile(email: string) {
    return this.http.get<UserProfile>(
      `${environment.apiUrl}/user/profile/${email}`
    )
    // .pipe(
    //   catchError((e) => {
    //     if (e.status === 500) {
    //       return throwError(() => new Error('Unexpected error!'))
    //     }

    //     return EMPTY
    //   })
    // )
  }

  getUsersByCompany() {
    return this.http
      .get<User[]>(`${environment.apiUrl}/user/companyusers`)
      .pipe(
        catchError((e) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }

          return EMPTY
        })
      )
  }

  createUser(newUser: CreateUserData) {
    return this.http.post<CreateUserResponseUnconfirmed>(
      `${environment.apiUrl}/user`,
      newUser
    )
  }

  editUser(email: string, info: EditUserRequest) {
    return this.http
      .put<BaseResponse>(`${environment.apiUrl}/user/${email}`, info)
      .pipe(
        catchError((e) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }

          return EMPTY
        })
      )
  }

  deleteUser(email: string) {
    return this.http
      .delete<BaseResponse>(`${environment.apiUrl}/user/${email}`)
      .pipe(
        catchError((e) => {
          if (e.status === 500) {
            return throwError(() => new Error('Unexpected error!'))
          }

          return EMPTY
        })
      )
  }
}
