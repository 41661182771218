import { Component, OnInit } from '@angular/core'
import { MenuService } from 'app/services/menu.service'
import { CookieService } from 'ngx-cookie-service'

@Component({
  selector: 'app-negotiations',
  templateUrl: './negotiations.component.html',
  styleUrls: ['./negotiations.component.scss']
})
export class NegotiationsComponent implements OnInit {
  url

  constructor(private menuService: MenuService,
    private cookies: CookieService) {}

  ngOnInit() {
    this.url = this.menuService.getScreen('NEGV', true).url + this.cookies.get('at') + '&mode='+(localStorage.getItem('theme') == 'grey' ? 'light' : 'dark')
  }
}
