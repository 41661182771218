<h3 mat-dialog-title>Add user</h3>
<div mat-dialog-content class="dialog-content" [formGroup]="addUserForm">
  <div class="dialog-column">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Given name</mat-label>
      <input matInput formControlName="givenName">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Middle name</mat-label>
      <input matInput formControlName="middleName">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Birth date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="birthDate">
      <mat-hint>DD/MM/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput type="email" formControlName="email">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Phone number</mat-label>
      <input matInput type="tel" formControlName="phoneNumber">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Identifier</mat-label>
      <input matInput formControlName="identifier">
    </mat-form-field>
  </div>
  <div class="dialog-column">
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input matInput formControlName="password" type="password">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Role</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let role of data.roles" [value]="role.id">
          {{ role.code }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Language</mat-label>
      <mat-select formControlName="language">
        <mat-option *ngFor="let language of data.languages" [value]="language.id">
          {{ language.name.charAt(0).toUpperCase() + language.name.slice(1) }}
        </mat-option>
      </mat-select>
    </mat-form-field> 
    <mat-form-field appearance="outline">
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender">
        <mat-option value="Male">
          Male
        </mat-option>
        <mat-option value="Female">
          Female
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<mat-dialog-actions class="dialog-actions">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="submit()">Create</button>
</mat-dialog-actions>