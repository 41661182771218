import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'listUsersFilter'
})
export class ListUsersFilterPipe implements PipeTransform {
  // TODO: Improve text search.
  transform(items: any[], role: number, email: string): any[] {
    let tempItems = items

    if (items === null) return []
    if (role) {
      tempItems = tempItems.filter((item) => item.role_id == role)
    }
    if (email) {
      tempItems = tempItems.filter((item) => item.email === email)
    }

    return tempItems
  }
}
