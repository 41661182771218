import { DatePipe } from '@angular/common'
import { Component, Inject } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { roles: Array<any>; languages: Array<any> },
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    public ref: MatDialogRef<AddUserDialogComponent>
  ) {}

  addUserForm = this.formBuilder.group({
    name: ['', Validators.required],
    givenName: ['', Validators.required],
    middleName: ['', Validators.required],
    birthDate: ['', Validators.required],
    email: ['', Validators.compose([Validators.required, Validators.email])],
    phoneNumber: ['', Validators.required],
    identifier: ['', Validators.required],
    password: ['', Validators.required],
    role: ['', Validators.required],
    language: ['', Validators.required],
    gender: ['', Validators.required]
  })

  submit() {
    const { birthDate, ...rest } = this.addUserForm.value

    this.ref.close({
      birthDate: this.datePipe.transform(birthDate, 'dd/mm/yyyy'),
      ...rest
    })
  }
}
