<div class="privacy-container">
  <div class="privacy-logo">
    <img src="assets/img/vx-management-logo-n.png">    
  </div>
  <h1>Privacy statement</h1>
  <span>
    <p>
      We inform you that personal data collected through the contact forms from www.viavox.com will be processed by
      VIAVOX INTERACTIVE, S.L., in order to manage your enquiry and, where appropriate, the maintenance of professional
      and commercial relations with you. Your data will not be transferred to third parties. This treatment of data is
      necessary to attend your request and is based on the consent that you give us when you send us your query. Unless
      you object to the processing of your data, they will be kept until 3 years after you send VIAVOX INTERACTIVE,
      S.L.. your query or, where applicable, as long as the business relationship and the deadlines set by tax law are
      maintained or during the deadlines established for dealing with possible claims.
    </p>
    <p>
      The personal data that you may provide during your contractual, professional or commercial relationship with
      VIAVOX INTERACTIVE, S.L., will be treated by the same with the purpose of maintaining these relations, as well as
      for sending communications that may be of interest. You can oppose freely to receive such communications, without
      prejudice to our contractual, commercial and professional relationships. To carry out this management is your data
      may be transferred to banks and the Tax Administration. This data processing is necessary for the execution of the
      contract and/or for the maintenance of the contractual, professional or commercial relationship. Likewise, we
      inform you that your data will be kept for as long as these relations and data are maintained. periods set by tax
      legislation or during the periods established to deal with possible claims.
    </p>
    <p>
      If you have sent us your curriculum to the postal or electronic addresses of VIAVOX INTERACTIVE, S.L., or if you
      have provided it to us. through a job offer published in professional social networks, such as Linkedin or
      Infojobs, we inform you that your data will be treated by VIAVOX INTERACTIVE, S.L.. for the management of your
      personnel selection processes of vacant positions in the company. Your data will not be passed on to third
      parties. This data processing is necessary to process your application for employment and is based on the consent
      you give us when you send us your resume. Unless you object to the processing of your data, these will be kept
      until 3 years after you send VIAVOX INTERACTIVE, S.L. your CV or, if applicable, as long as the relationship is
      maintained. or mercantile and the terms established by labour and tax legislation or during the terms established
      to deal with possible claims.
    </p>
    <p>
      You can exercise your rights of access, rectification, suppression, opposition, portability and limitation of the
      treatment of your personal data. data addressed to VIAVOX INTERACTIVE, S.L., located at C/ ALBERT EINSTEIN, Nº 12
      - PLANTA 1 (PARQUE TECNOLÓGICO); 39011 SANTANDER (CANTABRIA), or to protecciondedatos@viavox.com, accompanied by a
      copy of your National Identity Card or replacement document. In any situation, you have the right to present a
      complaint to the Spanish Data Protection Agency (AEPD).
    </p>
  </span>
  <h1>Cookie policy</h1>
  <span>
    <p>
      VIAVOX collects its policy of collection and processing of cookies, in compliance with the provisions of Article
      22.2 of Law 34/2002 of July 11, Services Information Society and Electronic Commerce. The Cookies Policy will
      apply to those users who voluntarily visit VIAVOX's web pages, fill in data collection forms, access the tools
      that VIAVOX makes available to its clients to manage its services, or use any other service present on the web
      site that involves the communication of data to VIAVOX, or access to data by VIAVOX for the provision of its
      services. DEFINITION AND FUNCTION OF COOKIES A cookie is a small text file placed on your computer's hard drive
      that stores information about your use of an Internet page. Cookies allow a website, among other things, to store
      and retrieve information about a user's or your computer's browsing habits and, depending on what information they
      contain and how your computer uses it, they can be used to recognize you. Cookies can only be read by the server
      that placed them and cannot run any programs or viruses. VIAVOX cookies cannot read data from your hard drive. As
      a general rule, there are the following types of cookies:
    </p>
    <p>
      Depending on its expiry date: Session: Are a type of cookies designed to collect and store data while the user
      accesses a website. They expire at the end of the session. Persistent: They are a type of cookie in which the data
      is still stored in the terminal and can be accessed and processed for a period defined by the person responsible
      for the cookie, which can range from a few minutes to several years.
    </p>
    <p>
      According to their origin: Own: Enabled by the website you are browsing. From third parties: These are those that
      are sent to the user's terminal computer from a computer or domain that is not managed by the editor, but by
      another entity that processes the data obtained through cookies.
    </p>
    <p>
      Depending on its purpose: Technical Cookies are those that allow you as a user to navigate through a web page,
      platform or application and use the different options or services that exist in it, such as, for example,
      controlling traffic and data communication, identifying the session, accessing restricted access parts,
      remembering the elements that make up an order, carrying out the purchase process of an order, applying for
      registration or participation in an event, using security elements during navigation, storing content for the
      dissemination of videos or sound or sharing content through social networks. Analytical Cookies Are used to
      collect user activity statistics. They analyze the number of users who visit the website, the number of pages
      visited as well as the activity of users on the website and its frequency of use. The information collected is
      always anonymous in such a way that no link can be established between it and the natural person to whom it
      refers. Authentication Cookies are used by the website to maintain the user's session during each visit, being
      dominant in private environments of the website that require a user and password for access. Cookies used by
      social networks allow the user to share with their contacts in a social network the contents that are of interest
      by marking the corresponding button (plug-in) inserted in the website. Cookies used by external content plug-ins.
      These cookies are stored by third parties and are necessary for the provision of some type of service. Third-party
      cookies for the personalization of advertising spaces. They are stored by third parties and allow to manage the
      advertising space that the users visualize when accessing the web site.
    </p>
  </span>
  <h3>WHO USES THE INFORMATION STORED IN COOKIES?</h3>
  <span>
    <p>
      The information stored in the cookies on our website is used exclusively by us, with the exception of Google
      Analytics, which is used and managed by Google and by us for statistical purposes, and the payment transaction
      analysis cookie, which is generated exclusively when you make a purchase and is analysed by an external company
      with the aim of providing sufficient guarantees on the payment transactions made.
    </p>
  </span>
  <h3>ACCEPTANCE OF THE POLICY AND DELETION OF COOKIES</h3>
  <span>
    <p>
      By browsing and continuing on our website you are indicating to us that you consent to the use of the cookies set
      out above in the conditions contained in this Cookies Policy. The conservation time of the cookies will depend on
      the type and will always be the minimum indispensable to fulfill its purpose. As a user, you can configure your
      browser in such a way that it disables or blocks the reception of all or some of the cookies. The fact that you do
      not wish to receive these cookies does not constitute an impediment to accessing the information, although the use
      of some services may be limited. If, once the consent for the reception of cookies has been given, it is wished to
      withdraw it, those stored on the user's computer must be eliminated through the options of the different browsers.
    </p>
  </span>
  <div class="privacy-button"><button (click)="goLogin()" type="button" class="btn btn-color1">Go to login</button></div>
</div>