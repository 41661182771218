import { Component, OnInit } from '@angular/core'
import { EventsService } from 'app/services/events.service'

@Component({
  selector: 'app-subview',
  templateUrl: './subview.component.html',
  styleUrls: ['./subview.component.scss']
})
export class SubviewComponent implements OnInit {
  constructor(private eventsService: EventsService) {}

  subviewSrc: string

  ngOnInit() {
    this.eventsService.on('subviewChange', () => {
      console.log('TEST')
    })
  }
}
