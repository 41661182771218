import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'
import { catchError, EMPTY, throwError } from 'rxjs'

export interface Company {
  id: number
  logo_url: string
  background_login_url: string
  principal_url: string
  home_logo_url: string
  created_at: string
  updated_at: string
  deleted_at: string
}

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor(private http: HttpClient) {}

  getCompanies() {
    return this.http.get<Company[]>(`${environment.apiUrl}/company`).pipe(
      catchError((e) => {
        if (e.status === 500) {
          return throwError(() => new Error('Unexpected error!'))
        }

        return EMPTY
      })
    )
  }
}
